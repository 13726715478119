// Dependencias
import React, { useState, useEffect, useContext } from "react";
import { language } from './language.js';

// Contexto
import { ParametrosBusquedaContext } from "./parametrosBusqueda";

function Habitaciones({dropdownName}) {
    const { getOcupacion, dispatch } = useContext(ParametrosBusquedaContext);
    const [adults, setAdults] = useState(2);
	const [kids, setKids] = useState(0);
    const [habitaciones, setHabitaciones] = useState();
    const [edad0, setEdad0] = useState(0);
    const [edad1, setEdad1] = useState(0);
    
    useEffect(() => {
    
    	handleChangeKids(kids);
    	
		var inputOcupacion = document.getElementById("huespedesInput");
		inputOcupacion.value = adults + " " + language["adultos"][Liferay.ThemeDisplay.getLanguageId()] + ", " + kids + " " + language["ninos"][Liferay.ThemeDisplay.getLanguageId()];
		
		dispatch({
            type: 'UPDATE_OCUPACION', payload: [
            	{
	                adults: adults,
	                children: kids,
	                ages: ""
                }
            ]
        });
		
	}, [adults, kids]);
    
    function handleClick(e) {
    	
    }
    
    function incrementarAdults() {
		if(adults < 3)
			setAdults(adults => adults + 1);
			
		if(adults == 2) {
			setKids(kids => 0);
		}
	}
	
	function incrementarKids() {
		if(kids < 2 && adults < 3)
			setKids(kids => kids + 1);
	}
	
	function decrementarAdults() {
		if(adults > 1) {
			setAdults(adults => adults - 1);
		}
	}
	
	function decrementarKids() {
		if(kids > 0) {
			setKids(kids => kids - 1);
		}
	}
	
	function handleChangeKids(n) {
		for(var i = 0; i <= 1; i++) {
			if(document.getElementById("kidsAgeContainer" + i)) {
				if(kids > i) {
					document.getElementById("kidsAgeContainer" + i).style.display = "flex";
				} else {
					document.getElementById("kidsAgeContainer" + i).style.display = "none";
				}
			}
		}
	}
	
	function plusEdadCero() {
		if(edad0 < 12)
			setEdad0(edad0 => edad0 + 1);
	}
	
	function minusEdadCero() {
		if(edad0 > 0) {
			var kidsInputAgeContainer = document.getElementById('kidsInputAge0Container');
        	kidsInputAgeContainer.classList.remove("bg-gold-1");
        	setEdad0(edad0 => edad0 - 1);
        }
	}
	
	function plusEdadUno() {
		if(edad1 < 12)
			setEdad1(edad1 => edad1 + 1);
	}
	
	function minusEdadUno() {
		if(edad1 > 0) {
			var kidsInputAgeContainer = document.getElementById('kidsInputAge1Container');
        	kidsInputAgeContainer.classList.remove("bg-gold-1");
        	setEdad1(edad1 => edad1 - 1);
        }
	}

    return (
    	<>
        	<div className="flex items-center w-full h-full !border border-terra-8 rounded-lg !p-3 huespedesInput" id="huespedesInputContainer">
	          <span className="!px-2 iconHuespedesInput">
	            <a
	              href="#"
	              className="flex items-center justify-center w-fit button-link-sj no-underline"
	              id=""
	              data-astro-cid-ewiz6fue
	            >
	              <div
	                className="iconUsers h-6 w-6 bg-terra-8 duration-300"
	                data-astro-cid-ewiz6fue
	              ></div>
	            </a>
	          </span>
	          <input
	            id="huespedesInput"
	            type="text"
	            readOnly={true}
	            placeholder={dropdownName}
	            className="h-full w-full bg-[transparent] !p-1 outline-none"
	          />
	        </div>
	        <div className="absolute !pt-2 hidden opacity-0 transition-opacity duration-300 !pr-2 sm:pr-0 z-10 sm:z-[1] max-h-[50vh] lg:max-h-[40vh]" id="huespedesSection">
	        	<div className="flex flex-col bg-neutral-0 !p-2 gap-2 !border border-terra-8 rounded-lg overflow-y-auto max-h-[inherit]" id="detalleHuespedesSection">
	        		<div className="flex gap-2">
	        			<div className="flex items-center w-full h-full !border border-terra-8 rounded-lg !p-3 !w-[inherit] adultsInput" id="adultsInputContainer">
	                      <span className="!px-2">
	                        <a
	                          href="#"
	                          className="flex items-center justify-center w-fit button-link-sj no-underline"
	                          id=""
	                          data-astro-cid-ewiz6fue
	                        >
	                          <div className="iconAccount h-6 w-6 bg-terra-8 duration-300" data-astro-cid-ewiz6fue></div>
	                        </a>
	                      </span>
	                      <input
	                        id="adultsInput"
	                        type="text"
	                        readOnly={true}
	                        placeholder={language["adultos"][Liferay.ThemeDisplay.getLanguageId()]}
	                        className="h-full w-full bg-[transparent] !p-1 outline-none"
	                        value={adults}
	                      />
	                    </div>
	                    <a
	                      href="#"
	                      className="!border border-terra-8 !h-14 !w-14 rounded-lg hover:border-terra-8 hover:bg-terra-8 flex items-center justify-center w-fit button-link-sj no-underline"
	                      id="adultsButtonAdd"
	                      onClick={incrementarAdults}
	                      data-astro-cid-ewiz6fue
	                    >
	                      <div className="iconPlus h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300" data-astro-cid-ewiz6fue></div>
	                    </a>
	                    <a
	                      href="#"
	                      className="!border border-terra-8 !h-14 !w-14 rounded-lg hover:border-terra-8 hover:bg-terra-8 flex items-center justify-center w-fit button-link-sj no-underline"
	                      id="adultsButtonSubstract"
	                      onClick={decrementarAdults}
	                      data-astro-cid-ewiz6fue
	                    >
	                      <div className="iconSubstract h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300" data-astro-cid-ewiz6fue></div>
	                    </a>
	        		</div>
	        		<div className="flex gap-2">
	        			<div className="flex items-center w-full h-full !border border-terra-8 rounded-lg !p-3 !w-[inherit] kidsInput" id="kidsInputContainer">
	                      <span className="!px-2">
	                        <a
	                          href="#"
	                          className="flex items-center justify-center w-fit button-link-sj no-underline"
	                          id=""
	                          data-astro-cid-ewiz6fue
	                        >
	                          <div className="iconReader h-6 w-6 bg-terra-8 duration-300" data-astro-cid-ewiz6fue></div>
	                        </a>
	                      </span>
	                      <input
	                        id="kidsInput"
	                        type="text"
	                        readOnly={true}
	                        placeholder={language["ninos"][Liferay.ThemeDisplay.getLanguageId()]}
	                        className="h-full w-full bg-[transparent] !p-1 outline-none"
	                        value={(kids==0) ? "" : kids}
	                      />
	                    </div>
	                    <a
	                      href="#"
	                      className="!border border-terra-8 !h-14 !w-14 rounded-lg hover:border-terra-8 hover:bg-terra-8 flex items-center justify-center w-fit button-link-sj no-underline"
	                      id="kidsButtonAdd"
	                      onClick={incrementarKids}
	                      data-astro-cid-ewiz6fue
	                    >
	                      <div className="iconPlus h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300" data-astro-cid-ewiz6fue></div>
	                    </a>
	                    <a
	                      href="#"
	                      className="!border border-terra-8 !h-14 !w-14 rounded-lg hover:border-terra-8 hover:bg-terra-8 flex items-center justify-center w-fit button-link-sj no-underline"
	                      id="kidsButtonSubstract"
	                      onClick={decrementarKids}
	                      data-astro-cid-ewiz6fue
	                    >
	                      <div className="iconSubstract h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300" data-astro-cid-ewiz6fue></div>
	                    </a>
	        		</div>
	        		<div className="flex gap-2 kidsAgeContainer" id="kidsAgeContainer0" style={{display: 'none'}}>
	        			<div className="flex items-center w-full h-full !border border-terra-8 rounded-lg !p-3 !w-[inherit] kidsInputAgeContainer" id="kidsInputAge0Container">
	        				<span className="!px-2">
	        					<a href="#" className="flex items-center justify-center w-fit button-link-sj no-underline">
	        						<div className="iconReader h-6 w-6 bg-terra-8 duration-300"></div>
	        					</a>
	        				</span>
	        				<input id="kidsInputAge0" type="text" readonly={true} placeholder={language["edad-nino"][Liferay.ThemeDisplay.getLanguageId()]} value={(edad0==0) ? "" : edad0} className="h-full w-full bg-[transparent] kidsInputAge !p-1 outline-none"/>
	        			</div>
	        			<a href="#" onClick={plusEdadCero} className="kidsButtonAddAge !border border-terra-8 !h-14 !w-14 rounded-lg hover:border-terra-8 hover:bg-terra-8 flex items-center justify-center w-fit button-link-sj no-underline" id="kidsButtonAddAge0">
	        				<div className="iconPlus h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300"></div>
	        			</a>
	        			<a href="#" onClick={minusEdadCero} className="kidsButtonSubstractAge !border border-terra-8 !h-14 !w-14 rounded-lg hover:border-terra-8 hover:bg-terra-8 flex items-center justify-center w-fit button-link-sj no-underline" id="kidsButtonSubstractAge0">
	        				<div className="iconSubstract h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300"></div>
	        			</a>
	        		</div>
	        		<div className="flex gap-2 kidsAgeContainer" id="kidsAgeContainer1" style={{display: 'none'}}>
	        			<div className="flex items-center w-full h-full !border border-terra-8 rounded-lg !p-3 !w-[inherit] kidsInputAgeContainer" id="kidsInputAge1Container">
	        				<span className="!px-2">
	        					<a href="#" className="flex items-center justify-center w-fit button-link-sj no-underline">
	        						<div className="iconReader h-6 w-6 bg-terra-8 duration-300"></div>
	        					</a>
	        				</span>
	        				<input id="kidsInputAge1" type="text" readonly={true} placeholder={language["edad-nino"][Liferay.ThemeDisplay.getLanguageId()]} value={(edad1==0) ? "" : edad1} className="h-full w-full bg-[transparent] kidsInputAge !p-1 outline-none"/>
	        			</div>
	        			<a href="#" onClick={plusEdadUno} className="kidsButtonAddAge !border border-terra-8 !h-14 !w-14 rounded-lg hover:border-terra-8 hover:bg-terra-8 flex items-center justify-center w-fit button-link-sj no-underline" id="kidsButtonAddAge1">
	        				<div className="iconPlus h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300"></div>
	        			</a>
	        			<a href="#" onClick={minusEdadUno} className="kidsButtonSubstractAge !border border-terra-8 !h-14 !w-14 rounded-lg hover:border-terra-8 hover:bg-terra-8 flex items-center justify-center w-fit button-link-sj no-underline" id="kidsButtonSubstractAge1">
	        				<div className="iconSubstract h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300"></div>
	        			</a>
	        		</div>
	        	</div>
	        	<div className="!my-1 flex bg-neutral-0 !p-2 !border border-terra-8 rounded-lg">
                  <a
                    href="#" onClick={handleClick}
                    className="uppercase flex items-center gap-2 !border border-terra-8 w-full rounded-lg !p-3 hover:border-terra-8 hover:bg-terra-8 hover:text-neutral-0 transition-colors duration-300 flex items-center justify-center w-fit button-link-sj no-underline"
                    id="btnConfirmarHuespedes"
                    data-astro-cid-ewiz6fue
                  >
                    <div
                      className="iconApproveTick h-6 w-6 bg-terra-8 hover:bg-neutral-0 duration-300"
                      data-astro-cid-ewiz6fue
                    ></div>
                    <div data-astro-cid-ewiz6fue>{language["confirmar-huespedes"][Liferay.ThemeDisplay.getLanguageId()]}</div>
                  </a>
                </div>
	        </div>
	       </>
    )
}

export default Habitaciones