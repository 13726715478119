// Dependencias
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

// Componentes
import CloseBtn from './CloseBtn';
import InputSearch from './InputSearch';
import ButtonSubmit from './ButtonSubmit';

// Contexto
import { ParametrosBusquedaProvider } from './parametrosBusqueda';

// Datos
import { datosEntrada } from './constans';
import { language } from './language';

import { Liferay as LiferayAPI } from './common/services/liferay/liferay';
import apicommon from './common/services/liferay/apicommon';

const MotorBusqueda = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const MotorBusqueda = useRef();
  const [urlFlight, setUrlFlight] = useState('');
  
  useEffect(() => {
		async function getUrlFlight() {
			var newUrlFlight = Liferay.ThemeDisplay.getLanguageId().split("_")[0];
			setUrlFlight(newUrlFlight);
		};
			
		if(!urlFlight)
			getUrlFlight();
	}, []);

  /**
   * Función para cerrar el motor de b�squeda
   */
  function openSearch() {
    setIsSearchOpen(true);
  }

  /**
   * Funci�n para cerrar el motor de b�squeda
   */
  function closeSearch() {
    setIsSearchOpen(false);
  }

  /**
     * Funci�n que maneja el submit del formulario
     */
  function handleSubmit(e) {
    e.preventDefault();
  }

  // Ocultar el motor de b�squeda al llegar al final de la página
  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (!isSearchOpen) {
        if ((window.scrollY + window.innerHeight) >= (document.body.clientHeight - 200)) {
          MotorBusqueda.current?.classList.contains('hidden') ? null : MotorBusqueda.current.classList.add('hidden');
        } else {
          MotorBusqueda.current?.classList.contains('hidden') ? MotorBusqueda.current.classList.remove('hidden') : null;
        }
      }
    })
  })

  function openBookingtravel() {
    window.open('https://bookingtravel.hotelsonjaumell.com/' + urlFlight + "/");
  }


  return (
    <ParametrosBusquedaProvider>
      <div className="fixed bottom-0 !px-6 sm:!px-0 flex flex-col justify-center w-full z-30 transition-all duration-300" id="reserveCard">

        <div className="flex lg:hidden bg-neutral-0 container !mx-auto rounded-lg !my-4 !p-2 gap-4 shadow-2xl" id="reserveFrameCardMobile">
          <a href="#" className="h-14 w-fit !p-3 border border-dark-5 bg-neutral-0 hover:bg-terra-8 rounded-lg transition-colors duration-300 flex items-center justify-center w-fit button-link-sj no-underline" id="serviceCenterReserve" data-astro-cid-ewiz6fue="">
            <div className="iconCall h-6 w-6 bg-dark-5 hover:bg-neutral-0 transition-colors duration-300" data-astro-cid-ewiz6fue=""></div>
          </a>
          <a href="#" className="h-14 w-fit !p-3 border border-dark-5 bg-neutral-0 hover:bg-terra-8 rounded-lg transition-colors duration-300 flex items-center justify-center w-fit button-link-sj no-underline" id="insideReserve" data-astro-cid-ewiz6fue="">
            <div className="iconAccount h-6 w-6 bg-dark-5 hover:bg-neutral-0 transition-colors duration-300" data-astro-cid-ewiz6fue=""></div>
          </a>
          <a href="#" className="w-full bg-terra-5 gap-2 hover:bg-terra-8 hover:text-neutral-0 uppercase rounded-lg transition-colors duration-300 flex items-center justify-center w-fit button-link-sj no-underline" id="iconCallReserve" data-astro-cid-ewiz6fue="">
            <div className="iconCalendar h-6 w-6 bg-dark-5 hover:bg-neutral-0 transition-colors duration-300" data-astro-cid-ewiz6fue=""></div>
            {language["reservar"][Liferay.ThemeDisplay.getLanguageId()]}
          </a>
        </div>

        <div className="hidden lg:flex bg-neutral-0 flex-col right-4 lg:right-auto left-4 lg:left-auto lg:flex-row !w-auto 2xl:!w-[90vw] !mx-auto rounded-lg !my-4 !p-2 gap-4 justify-stretch shadow-8xl lg:shadow-2xl"
          style={{animationDuration: "500ms"}}
          id="reserveFrameCard">
          <div className="flex flex-col items-start lg:items-end gap-2">
            <a href="#"
              className="gap-2 text-link-md text-dark-5 btn-reserve-active btn-reserve-types flex items-center justify-center w-fit button-link-sj no-underline"
              id="hotelReserve" data-astro-cid-ewiz6fue>
              <div className="iconBed h-4 w-4 bg-dark-5" data-astro-cid-ewiz6fue></div>
              <div class="reserve-text-active" data-astro-cid-ewiz6fue>{language["Hotel"][Liferay.ThemeDisplay.getLanguageId()]}</div>
            </a>
            <a href="#" onClick={openBookingtravel}
              className="gap-2 text-link-md text-dark-5 btn-reserve-types flex items-center justify-center w-fit button-link-sj no-underline"
              id="hotelFlightReserve" data-astro-cid-ewiz6fue>
              <div className="iconPlane h-4 w-4 bg-dark-5" data-astro-cid-ewiz6fue></div>
              <div class="reserve-text-active w-full" data-astro-cid-ewiz6fue>{language["Hotel-vuelo"][Liferay.ThemeDisplay.getLanguageId()]}</div>
            </a>
          </div>
          <div className={isSearchOpen ? "flex flex-col lg:flex-row gap-2 grow" : "flex flex-col lg:flex-row gap-2 grow"} ref={MotorBusqueda}>
                {datosEntrada.map((dato) => <InputSearch key={dato.id} isSearchOpen={isSearchOpen} dato={dato} />)}
                <ButtonSubmit submitAction={handleSubmit} />
          </div>
        </div>

      </div>
    </ParametrosBusquedaProvider>
  )
};

class WebComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {

    createRoot(this).render(
      <MotorBusqueda
      />,
      this
    );
  }
}

const ELEMENT_ID = 'liferay-hotel-sonjaumell-motor-reserva';

if (!customElements.get(ELEMENT_ID)) {
  customElements.define(ELEMENT_ID, WebComponent);
}
