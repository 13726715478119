// Dependencias
import React, { useEffect, useState, useContext } from "react";

// Contexto
import { ParametrosBusquedaContext } from "./parametrosBusqueda";

function PromoCode({dropdownName}) {

    // Usar dispatch del contexto ParametrosBusquedaContext
    const { dispatch } = useContext(ParametrosBusquedaContext);
    
    /**
     * Función para actualizar el valor del input editable
     * @param {*} input 
     */
    function handleInputChange(input) {
            dispatch({
                type: 'UPDATE_CODIGO',
                payload: input
            });
    }

    return (
              <div
                className="flex items-center w-full h-full !border border-terra-8 rounded-lg !p-3"
                id="promotionalCodeInputContainer"
              >
                <span className="!px-2 cursor-default">
                  <a
                    href="#"
                    className="flex items-center justify-center w-fit button-link-sj no-underline"
                    id=""
                    data-astro-cid-ewiz6fue
                  >
                    <div
                      className="iconTag h-6 w-6 bg-terra-8 duration-300 cursor-default"
                      data-astro-cid-ewiz6fue
                    ></div>
                  </a>
                </span>
                <input type="text" className="h-full w-full bg-[transparent] !p-1 outline-none" id="promotionalCodeInput" placeholder={dropdownName} onChange={(e) => handleInputChange(e.target.value)}/>
              </div>
    )
}

export default PromoCode